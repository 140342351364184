import axios from "axios"
import Home from '@/view/Home.vue'
import AmeliaPascoaLayout from '../layouts/AmeliaPascoaLayout.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'

/* eslint-disable */
// @ts-ignore
const aliasesJsonCategoryLines = require('../../aliasesCategoryLines.json');
// console.log(aliasesJsonCategoryLines);

const aliasesJsonCategory = require('../../aliasesCategory.json');
// console.log(aliasesJsonCategory);

const aliasesJsonProducts = require('../../aliasesProducts.json');
// console.log(aliasesJsonProducts);


export const routes = [
    {
        path: '/',
        name: 'Home',
        meta: { layout: DefaultLayout },
        component: Home,
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: { layout: DefaultLayout },
        component: () => import('@/view/404.vue'),
    },
    {
        path: '/:name_brand',
        name: 'Brands Amigavel',
        component: () => import('@/view/Brands.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/brands`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.brands.map((obj) => obj.id);
                    // return data.brands.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:id',
        name: 'Brands',
        component: () => import('@/view/Brands.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/brands`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.brands.map((obj) => obj.id);
                    // return data.brands.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/produtos/:slug',
        name: 'Category Products Amigavel',
        component: () => import('@/view/Category.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/brand-sections`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.brandSections.map((obj) => obj.id);
                    // return data.brandSections.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/profissional/produtos/:slug',
        name: 'Category Products Amigavel 2',
        component: () => import('@/view/Category.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/brand-sections`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.brandSections.map((obj) => obj.id);
                    // return data.brandSections.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/categorias/:id',
        name: 'Category Products',
        component: () => import('@/view/Category.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/brand-sections`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.brandSections.map((obj) => obj.id);
                    // return data.brandSections.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/produtos/:name_category/:id',
        name: 'Category Lines Amigavel',
        component: () => import('@/view/CategoryLines.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/product-subcategories`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.subcategories.map((obj) => obj.id);
                    // return data.subcategories.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/produtos/profissional/:name_category/:id',
        name: 'Category Lines Amigavel 2',
        component: () => import('@/view/CategoryLines.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/product-subcategories`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.subcategories.map((obj) => obj.id);
                    // return data.subcategories.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/subcategorias/:id',
        name: 'Category Lines',
        component: () => import('@/view/CategoryLines.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/product-subcategories`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.subcategories.map((obj) => obj.id);
                    // return data.subcategories.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/produtos/:name_category/:sub_name_category/:id',
        name: 'Product Amigavel',
        component: () => import('@/view/Product.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/products`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.products.map((obj) => obj.id);
                    // return data.products.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/:name_brand/produtos/profissional/:name_category/:sub_name_category/:id',
        name: 'Product Amigavel 2',
        component: () => import('@/view/Product.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                ignoreRoute: true,
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/products`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.products.map((obj) => obj.id);
                    // return data.products.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/produto/:id',
        name: 'Product',
        component: () => import('@/view/Product.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/products`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.products.map((obj) => obj.id);
                    // return data.products.map((obj) => obj.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/receitas',
        name: 'Recipes',
        component: () => import('@/view/Recipes.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/receitas/:slug',
        name: 'Recipes Details',
        component: () => import('@/view/recipes/RecipesDetails.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/recipes`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.recipes.data.map((obj) => obj.id);
                    // return data.recipes.map((obj) => obj.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/noticias',
        name: 'News',
        component: () => import('@/view/News.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/noticias/:slug',
        name: 'News Details',
        component: () => import('@/view/news/NewsInside.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/news`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.news.map((obj) => obj.id);
                    // return data.news.map((obj) => obj.slug.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    {
        path: '/imprensa',
        name: 'release',
        component: () => import('@/view/Release.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/imprensa/:slug',
        name: 'Release Details',
        component: () => import('@/view/release/ReleaseInside.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: async () => {
                    const { data } = await axios.get(`${process.env.VUE_APP_BASE_REQUEST_URL}/api/releases`)
                    /* eslint-disable */
                    // @ts-ignore
                    return data.releases.map((obj) => obj.id);
                    // return data.releases.map((obj) => obj.slug.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));
                }
            }
        },
    },
    // {
    //     path: '/parceiros',
    //     name: 'Partneship',
    //     component: () => import('@/view/Partnership.vue'),
    //     meta: { layout: DefaultLayout },
    // },
    // {
    //     path: '/parceiros/istituto-germinare',
    //     name: 'Partneship Germinare',
    //     component: () => import('@/view/partnership/PartnershipGerminare.vue'),
    //     meta: { layout: DefaultLayout },
    // },
    // {
    //     path: '/parceiros/projeto-escola',
    //     name: 'Partneship School',
    //     component: () => import('@/view/partnership/PartnershipSchool.vue'),
    //     meta: { layout: DefaultLayout },
    // },
    {
        path: '/nossa-cultura',
        name: 'Our Culture',
        component: () => import('@/view/OurCulture.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/nossas-lojas',
        name: 'Our Stores',
        component: () => import('@/view/OurStores.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/relacao-com-investidores',
        name: 'Investor Relations',
        component: () => import('@/view/InvestorRelations.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/trabalhe-conosco',
        name: 'Work with us',
        component: () => import('@/view/WorkUs.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/fale-conosco',
        name: 'Contact',
        component: () => import('@/view/Contact.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/exportacao',
        name: 'Export ',
        component: () => import('@/view/Export.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/nossa-historia',
        name: 'Our History',
        component: () => import('@/view/OurHistory.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor',
        name: 'Playing with Vigor',
        component: () => import('@/view/KidsVigor.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/manual-da-diversao',
        name: 'Playing with Vigor, Manual',
        component: () => import('@/view/kids/Manual.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/aquario',
        name: 'Playing with Vigor, Aquarium',
        component: () => import('@/view/kids/Aquarium.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/binoculo',
        name: 'Playing with Vigor, Binoculars',
        component: () => import('@/view/kids/Binoculars.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/cabelinho',
        name: 'Playing with Vigor, Hair',
        component: () => import('@/view/kids/Hair.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/nave-espacial',
        name: 'Playing with Vigor, Spacecraft',
        component: () => import('@/view/kids/Spacecraft.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/cantina',
        name: 'Playing with Vigor, canteen',
        component: () => import('@/view/kids/Canteen.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/ache-o-minion',
        name: 'Playing with Vigor, find the minion',
        component: () => import('@/view/kids/FindTheMinion.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/brincando-com-vigor/colecoes',
        name: 'Playing with Vigor, Collections',
        component: () => import('@/view/kids/Collections.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/politica-de-privacidade',
        name: 'Privacy Policy',
        component: () => import('@/view/PrivacyPolicy.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/buscar',
        name: 'Search',
        component: () => import('@/view/Search.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/academia-do-queijo',
        name: 'Cheese Academy',
        component: () => import('@/view/CheeseAcademy.vue'),
        meta: { layout: DefaultLayout },
    },
    // {
    //     path: '/jornada-asg',
    //     name: 'Governance',
    //     component: () => import('@/view/Asg.vue'),
    // },
    {
        path: '/jornada-asg/governanca',
        name: 'Asg',
        component: () => import('@/view/asg/Governance.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/viv/protein',
        redirect: { name: 'Viv Protein' },
    },
    {
        path: '/saudavel/vigor-viv-protein-iogurte',
        redirect: { name: 'Viv Protein' },
    },
    {
        path: '/saudavel/vigor-viv-protein-uht',
        redirect: { name: 'Viv Protein' },
    },
    {
        path: '/viv-protein',
        name: 'Viv Protein',
        component: () => import('@/view/VivProtein.vue'),
        meta: { layout: DefaultLayout },
    },
    {
        path: '/amelia/pascoa',
        name: 'Amelia Pascoa',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/AmeliaPascoa.vue'),
    },
    {
        path: '/amelia/pascoa/pdf',
        name: 'Amelia Pascoa pdf',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/AmeliaPascoa.vue'),
    },
    {
        path: '/regulamento_VIGOR_MIX_KIDS2024',
        name: 'Regulamento Vigor Mix Kids 2024',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/RegulamentoVigorMix.vue'),
    },
    {
        path: '/clube-de-cinema',
        name: 'Clube de cinema',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/ClubeDeCinema.vue'),
    },
    {
        path: '/m-compre-ganhe-minions',
        name: 'Compre ganhe minios',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/CompreGanheMinios.vue'),
    },
    {
        path: '/compre-ganhe-gramado',
        name: 'Compre ganhe',
        meta: { layout: AmeliaPascoaLayout },
        component: () => import('@/view/CompreGanhe.vue'),
    },
    {
        path: '/pascoavigor',
        name: 'Páscoa Vigor',
        component: () => import('@/view/PascoaVigor2025.vue'),
    },
    // {
    //     path: '/queijos/queijos-artesanais',
    //     redirect: { name: 'Artisan Chesse Amigavel' },
    // },
    // {
    //     path: '/queijos-artesanais',
    //     name: 'Artisan Chesse Amigavel',
    //     component: () => import('@/view/ArtisanCheese.vue'),
    // },
    // {
    //     path: '/queijos-artesanais/:name_variant',
    //     name: 'Artisan Chesse Variante Amigavel',
    //     component: () => import('@/view/ArtisanCheese/ArtisanCheeseInside.vue'),
    //     meta: {
    //         sitemap: {
    //             ignoreRoute: true,
    //         }
    //     }
    // },
    // REDIRECTS ANTIGO SITE
    {
        path: '/linha/vigor-grego-individual',
        redirect: '/iogurtes/iogurte-grego-individual',
    },
    {
        path: '/viv',
        redirect: '/saudavel',
    },
    {
        path: '/codigo-de-etica',
        redirect: '/nossa-cultura',
    },
    // novas rotas
    {
        //path: '/:name_brand/:slug_sub_brand/:id',
        path: '/categoryproducts',
        alias: aliasesJsonCategory,
        name: 'Category Products 2.0',
        component: () => import('@/view/Category.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: aliasesJsonCategory,
            }
        },
    },
    {
        //path: '/:name_brand/:name_sub_category/:id',
        path: '/categorylines',
        alias: aliasesJsonCategoryLines,
        name: 'Category Lines 2.0',
        component: () => import('@/view/CategoryLines.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: aliasesJsonCategoryLines,
            },
        },
    },
    {
        //path: '/:name_brand/:name_category/:name_product/:id',
        path: '/product',
        alias: aliasesJsonProducts,
        name: 'Product 2.0',
        component: () => import('@/view/Product.vue'),
        meta: {
            layout: DefaultLayout,
            sitemap: {
                slugs: aliasesJsonProducts,
            }
        },
    },
]
