export const ptLocale = {
    "pt": {
        "header": {
            "fale-conosco": "Fale conosco",
            "acessibilidade": "Acessibilidade",
            "alto-contraste": "Alto-contraste",
            "aumentar-fonte": "Aumentar fonte",
            "tamanho-normal": "Tamanho normal",
            "diminuir-fonte": "Diminuir fonte",
            "institucional": "Institucional",
            "nossa-historia": "Nossa História",
            "nossas-lojas": "Nossas Lojas",
            "jornada-asg": "Jornada ASG",
            "parceria-iniciativa": "Parceria / Iniciativa",
            "exportacao": "Exportação",
            "imprensa": "Imprensa",
            "nossas-marcas": "Nossas Marcas",
            "receitas": "Receitas",
            "noticias": "Notícias",
            "area-kids": "Área kids",
            "academia-do-queijo": "Academia do queijo",
            "trabalhe-conosco": "Trabalhe conosco",
            "voltar": "Voltar",
            "procurar": "Procurar..."
        },
        "footer": {
            "empresa": "Uma empresa do grupo",
            "fale-conosco": "Fale conosco",
            "institucional": "Institucional",
            "nossa-historia": "Nossa História",
            "nossas-lojas": "Nossas Lojas",
            "jornada-asg": "Jornada ASG",
            "parceria-iniciativa": "Parceria / Iniciativa",
            "exportacao": "Exportação",
            "imprensa": "Imprensa",
            "nossas-marcas": "Nossas Marcas",
            "receitas": "Receitas",
            "noticias": "Notícias",
            "area-kids": "Área kids",
            "trabalhe-conosco": "Trabalhe conosco",
            "atendimento": "Atendimento ao Consumidor (SAC)",
            "contato-dados": "Contato com Encarregado de Dados Pessoais",
            "newsletter": "Registre-se na nossa newsletter: ",
            "seu-nome": "Seu nome",
            "seu-email": "Seu e-mail",
            "registre-se": "Registre-se",
            "politica-de-privacidade": "Política de Privacidade",
            "etica": "Código de Ética",
            "linha-etica": "Linha Ética",
            "todos-direitos-reservados": "Todos os direitos reservados",
        },
        "home": {
            "nossas-marcas": "Nossas Marcas",
            "nossa-historia": "Nossa História",
            "receitas": {
                "titulo": "Cozinhe com vigor",
                "descricao": "As melhores receitas para você deixar seu dia a dia ainda mais gostoso. Aproveite!",
                "botao": "Ver todas"
            },
            "trabalhe": {
                "titulo": "Trabalhe conosco",
                "descricao": "Veja as vagas disponíveis na Vigor",
                "botao": "Saiba mais"
            },
            "profissional": {
                "titulo": "Linha",
                "titulo-2": "Profissional",
                "descricao": "Produtos pensados para o seu negócio",
                "botao": "Saiba mais"
            },
            "carousel-nossa-historia": {
                "carousel-1": "Leite em pó para fins industriais",
                "carousel-2": "Vigor lança o delicioso Leite condensado",
                "carousel-3": "Vigor se torna pioneira na distribuição de leite pasteurizado e engarrafado em São Paulo",
                "carousel-4": "Inauguração de uma fábrica em Paraisópolis e em 1940 em São Gonçalo do Sapucaí, para produzir o Queijo tipo Minas e Queijo tipo Parmesão respectivamente.",
                "carousel-5": "Lançamento do primeiro iogurte natural do Brasil. Neste mesmo ano, foi lançado o leite em garrafa de vidro com 1 litro.Anos depois, a Vigor traz para o mercado embalagens de filme plástico",
                "carousel-6": "A Vigor lança novos sabores de iogurte com polpa de frutas.",
                "carousel-7": "A Vigor é uma das primeiras a lançar no mercado o leite esterilizado com tecnologia UHT.",
                "carousel-8": "Pioneira no lançamento do iogurte natural com cereais, o Mix Vigor.",
                "carousel-9": "A Vigor lança uma mistura inovadora no mercado de Spreads, o primeiro Blend de Manteiga com Margarina, a Vigor Mix.",
                "carousel-10": "A Vigor lança Vigorzinho, sua linha de petit suisse.",
                "carousel-11": "O consumidor é apresentado ao Leite Fermentado Vigor.",
                "carousel-12": "A marca Vigor lança sua linha de maioneses e amplia ainda mais a de margarinas.",
                "carousel-13": "A linha Vigor Grego chega para mudar definitivamente o conceito de iogurte.",
                "carousel-14": "Extensão do portfólio de Vigor Grego para versão Zero Gorduras e Zero adição de açúcares.",
                "carousel-15": "Lançamento da embalagem econômica de Iogurte Líquido Vigor e renovação da linha infantil Vigor.",
                "carousel-16": "Em 2015, a Vigor revolucionou a forma de se comer margarina, lançando a sua Margarina sabor manteiga. Produto que caiu no gosto do consumidor.",
                "carousel-17": "Disponível em três diferentes sabores, chegou aos mercados o Vigor Grego Pedaços: primeiro e único iogurte com pedaços de frutas de verdade sem corantes e aromatizantes.",
                "carousel-18": "Vigor lança a marca Viv que mistura sabor e leveza para quem quer manter uma alimentação saudável, mas sem abrir mão de saborear um iogurte gostoso, tendo em seu portfólio uma variedade de produtos para combinar com todas as ocasiões do dia.",
                "carousel-19": "Viv lança o novo UHT protein, uma bebida láctea pensada para quem pratica esporte e gosta de cuidar da saúde, sem perder o hábito de consumir um produto saudável e saboroso. Nosso UHT contém 15g de proteínas para ajudar na sua dieta, não contém lactose e nem adição de açúcares e além disso contém Whey Protein, deixando tudo em equilíbrio.",
            }
        },
        "ourHistory": {
            "titulo": "Pioneirismo, Tradição e Experiência",
            "paragrafo": "Preocupação em atender aos anseios e às necessidades de seus consumidores em todos os níveis. Rigorosa atenção à qualidade de todos os seus produtos, do processamento à exposição no ponto de venda. São virtudes que fizeram da Vigor uma referência no setor alimentício brasileiro e contribuem para sua constante evolução, colocando-a frequentemente à frente de seu tempo.",
        },
        "ourStores": {
            "horarios": "Horário de funcionamento",
            "dia-1": "De segunda a quinta: ",
            "horario-1": "das 7h30 as 18h",
            "dia-2": "Sexta: ",
            "horario-2": "das 6h30 as 18h30",
            "dia-3": "Sábado: ",
            "horario-3": "das 9h as 13h",
            "paragrafo": "As lojas da Vigor Alimentos estão sempre de portas abertas para receber você. Nelas você encontra produtos de todas as nossas marcas, em um ambiente onde a qualidadedos produtos e do atendimento são as grandes ofertas. Seja bem-vindo!",
            "horario": "Horário de Atendimento",
            "loja": "Loja",
            "atendimento": "Horário de Atendimento",
            "domingo": "Domingo e Feriado - Fechado",
            "loja-1": {
                "horario-1": "Segunda – Sexta 07:00h – 18:30h",
                "horario-2": "Sábado – 08:00h – 14:30h",
            },
            "loja-2": {
                "horario-1": "Segunda – Sexta 07:00h – 19:00h",
                "horario-2": "Sábado – 07:00h – 14:30h",
            },
            "loja-3": {
                "horario-1": "Segunda – Sexta 08:00h – 18:00h",
                "horario-2": "Sábado – 08:00h – 12:00h",
            },
            "loja-4": {
                "horario-1": "Segunda – Sexta 08:00h – 18:00h",
                "horario-2": "Sábado, Domingo e Feriado – Fechado",
            },
        },
        "asg": {
            "governanca": {
                "titulo": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a dictum dolor, nec tincidunt tortor. Etiam euismod vehicula consequat. Vivamus placerat in elit sed dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a dictum dolor, neipsum.tincidunt tortor. Etiam euismod vehicula consequat. Vivamus placerat in elit sed dictum. Lorem ipsum dolor sit amet, consectetur adipiscing.",
                "financeira": {
                    "titulo": "Demonstrações financeiras",
                    "descricao": "A Vigor prioriza e reforça seu compromisso de manter total transparência em seu relacionamento com seus investidores. Por isso apresentamos para consulta nossas demonstrações financeiras anuais auditadas.",
                    "sub-titulo-1": "Acesse as demonstrações financeiras",
                    "sub-titulo-2": "Outras publicações relevantes",
                    "botao": "Fato Relevante Nº 01 - 2021",
                },
                "etica": {
                    "titulo": "Código de ética",
                    "descricao": "O Código de Ética é o documento que nos orienta sobre as expectativas da VIGOR em relação às principais regras de conduta e princípios éticos, sempre alinhados com os valores da VIGOR. Se aplica a todos os colaboradores da VIGOR e a terceiros que agem em nome e benefício da VIGOR. O Código de Ética também nos orienta em relação ao cumprimento de leis e das regras internas da VIGOR.",
                    "titulo-2": "Canal de Ética",
                    "descricao-2": "Em caso de qualquer situação de descumprimento de leis e das regras internas, pedimos que reporte o fato por meio Canal de Ética, em um dos canais abaixo disponibilizados: ",
                    "titulo-3": "Para uma comunicação eficiente, pedimos que inclua sempre as seguintes informações: ",
                    "lista-1": "O que aconteceu?",
                    "lista-2": "Como aconteceu?",
                    "lista-3": "Quando aconteceu?",
                    "lista-4": "Onde aconteceu?",
                    "lista-5": "Quais as pessoas envolvidas?",
                    "titulo-4": "O Canal de Ética é administrado pela empresa independente Contato Seguro, especialista na gestão de relatórios e denúncias de forma confidencial e anônima (se assim preferir).",
                },
            },
        },
        "partnership": {
            "titulo": "Parcerias e Iniciativas",
            "descricao": "Conheça abaixo nossas parcerias e iniciativas.",
            "botao": "ver todas",
            "bloco-1": {
                "titulo": "Projeto Escola",
                "descricao": "Conheça mais sobre esse projeto educativo e inscreva sua escola para participar também.",
            },
            "bloco-2": {
                "titulo": "Instituto Germinare",
                "descricao": "A Vigor apoia a educação no Brasil para a formação de líderes em negócios.",
            },
            "protejo-1": {
                "titulo": "O projeto",
                "descricao": "Há mais de 30 anos, a Vigor desenvolve o Projeto Escola, dedicado com muito amor e carinho ao público infantil. O objetivo é ensinar as crianças de uma maneira lúdica e divertida o processo de fabricação do iogurte e demais derivados do leite, através de uma visita pedagógica à fábrica da Vigor. Desenvolvemos também um trabalho de conscientização sobre o meio ambiente e a pirâmide alimentar, sendo estes apresentados através de um vídeo com uma linguagem adequada à faixa etária atendida, além de explanações das monitoras, que estão sempre dispostas a esclarecer as dúvidas existentes.",
                "titulo-2": "Atividades e aprendizado",
                "lista-1": "Oficina de reciclagem",
                "lista-2": "Visita monitorada à fábrica",
                "lista-3": "Importância da boa alimentação",
                "lista-4": "Relação com o meio ambiente",
                "titulo-3": "Como funciona?",
                "paragrafo": "O Projeto Escola é gratuito*",
                "paragrafo-2": "e acontece durante todo o período letivo, para crianças de 6 a 12 anos das redes municipais, estaduais, particulares, demais instituições e grupos da terceira idade. Funciona de segunda a sexta-feira em dois horários, das 9h30 às 12h e das 13h30 às 16h, com grupos de até 40 visitantes por período. É importante ressaltar que disponibilizamos somente 80 vagas por instituição, dando oportunidade a todos que queiram participar. * transporte não incluso.",
            },
            "protejo-2": {
                "titulo": "O Instituto",
                "paragrafo-1": "Fundado em 2009, o Instituto vê na Educação o principal instrumento para transformar e desenvolver uma sociedade, e através de sua Escola, tem como objetivo oferecer, sem custo, um ensino acadêmico de excelência para jovens potenciais, associado ao desenvolvimento de competências e habilidades para que se tornem futuros gestores e líderes de negócios com uma visão ampla, empreendedora e inovadora para o mercado de trabalho e para a vida pessoal.",
                "paragrafo-2": "Para transmitir conhecimentos sobre gestão de negócios, a Escola Germinare, que é aprovada pelo Ministério da Educação – MEC – e reconhecida pela UNESCO - Organização das Nações Unidas para a Educação, Ciência e Cultura - oferece ampla preparação destes jovens para o ambiente profissional, com conteúdos dirigidos de administração com foco em gestão de projetos, finanças, pessoas, marketing, operações, governança corporativa, estratégia e logística.",
                "paragrafo-3": "Esse projeto pedagógico é exclusivo, e foi criado a partir da visão de que os jovens precisam de um ensino de alta qualidade e diferenciado, de forma a prepará-los para os desafios reais da vida profissional. Nesse sentido, os alunos da Germinare realizam, em caráter permanente, projetos de gestão de negócios, visitas a Bolsa de Valores, e também a ambientes produtivos e corporativos, além de encontros com especialistas. São realizadas inúmeras atividades de simulação com aplicação no mundo real, inclusive com a realização de trabalhos práticos em parceria com empresas.",
                "titulo-2": "O diferencial",
                "paragrafo-4": "O diferencial da Germinare se sustenta a partir deste projeto acadêmico inédito e também através de uma grade curricular intensa para alunos a partir do 6º ano do Ensino Fundamental até a 3ª série do Ensino Médio. São 10 horas diárias de aulas, e nesse período, os alunos têm a oportunidade de mesclar o aprendizado dos conteúdos curriculares com o uso de tecnologia, esportes, idioma, música, artes e orientação de estudos.",
                "titulo-3": "Nosso desafio",
                "paragrafo-5": "Hoje, o grande desafio do Instituto é expandir este conceito de formação educacional para outras regiões do país, ampliando dessa maneira a oportunidade de um futuro promissor para os jovens talentos de todo o Brasil, e contribuindo assim para a formação de uma nova geração de líderes.",
            }
        },
        "export": {
            "titulo": "Catálogos de produtos",
            "descricao": "Para saber mais entre em contato com a área responsável pelo e-mail: ",
        },
        "release": {
            "titulo": "Press Office - Ketchum",
            "resultado": "Sem resultados para a busca",
            "linkNameBase": "Leia o release",
        },
        "news": {
            "titulo": "Notícias",
            "resultado": "Sem resultados para a busca",
            "leia": "Leia também",
            "linkNameBase": "Leia a notícia",
            "formulario": {
                "input-1": "Faça sua busca",
                "botao": "Pesquisar",
                "filtrar": "filtrar",
                "data": "Data",
            },
        },
        "recipes": {
            "titulo": "Cozinhe com Vigor e deixe sua comida mais gostosa",
            "descricao": "As melhores receitas para você deixar seu dia a dia ainda mais gostoso. Aproveite!",
            "categoria": "Categoria",
            "todas": "todas",
            "botao": "Ver receita",
            "interna": {
                "ingredientes": "Ingredientes",
                "preparo": "Modo de Preparo",
                "botao": "voltar",
                "titulo-1": "Produtos usados nessa receita",
                "titulo-2": "Conheça outras receitas",
            },
        },
        "kidsVigor": {
            "bloco-1": {
                "titulo": "Pronto pro seu momento em família mais divertido?",
                "descricao": "Brincadeiras e vídeos que ensinam a criar objetos divertidos reutilizando as embalagens da linha infantil da Vigor. Assista e descubra brincadeiras que estimulam a criatividade e ensinam sobre sustentabilidade de uma maneira leve e divertida."
            },
            "bloco-2": {
                "titulo": "Baixe o Manual da Diversão",
                "descricao": "Aqui você vai transformar seus momentos mais gostosos em brincadeiras muito divertidas. Juntos, você e as crianças vão descobrir novos jeitos de brincar.",
                "botao": "Ver todas"
            },
            "botao": "Brinque agora",
            "brincadeira-1": "Manual da diversão",
            "brincadeira-2": {
                "titulo-1": "Marionetes",
                "titulo-2": "Sapinhos Papa-Moscas",
                "titulo-3": "Vasinhos Customizados",
            },
            "brincadeira-3": "Fábrica de ideias",
            "manual": {
                "descricao": "Aqui você vai transformar seus momentos mais gostosos em brincadeiras muito divertidas. Juntos, você e as crianças vão descobrir novos jeitos de brincar.",
                "descricao-2": "Pronto pro seu momento em família mais divertido?",
                "botao": "Baixar!",
                "titulo": "Produtos Linha Infantil",
                "carousel-1": "Pescaria divertida",
                "carousel-2": "Barquinho vai e vem",
                "carousel-3": "Tubarão Vig",
                "carousel-4": "Trem Monstruoso",
                "carousel-5": "Boliche Amarelinho",
                "carousel-6": "Estica e Lança",
                "carousel-7": "Vig Monstro",
                "carousel-8": "Jogo da Velha",
                "carousel-9": "Telefoninho",
                "carousel-10": "Mão Esquerda, Mão direita",
            },
            "fabrica-de-ideias": {
                "titulo": "TCHARAM!",
                "descricao": "Olha só que lindão! É assim que vai ficar o brinquedo que você pode fazer em casa mesmo. Mãos à obra!",
                "titulo-2": "O que você vai precisar",
                "descricao-2": "Além de muita criatividade veja aqui, tim-tim por tim-tim, todo o material que você vai precisar pra fazer um brinquedo incrível.",
            },
            "aquario": {
                "carousel-1": "Uma embalagem de Leite fermentado Vigor",
                "carousel-2": "Uma caixa de papel (de preferência azul)",
                "carousel-3": "Cola em bastão Ou cola branca",
                "carousel-4": "Fita adesiva",
                "carousel-5": "Papéis coloridos",
                "carousel-6": "Tesoura sem ponta",
                "carousel-7": "Canetinhas e lápis para colorir",
                "carousel-8": "Fio de nylon",
            },
            "binocolo": {
                "carousel-1": "Cola quente",
                "carousel-2": "Lapiseira",
                "carousel-3": "Tesoura",
                "carousel-4": "Pincéis",
                "carousel-5": "Lã preta",
                "carousel-6": "Tinta preta",
                "carousel-7": "Balões coloridos",
                "carousel-8": "2 iorgurtes de garrafinha Vigor",
            },
            "cabelinho": {
                "carousel-1": "3 embalagens de leite fermentado Vigor",
                "carousel-2": "Funil",
                "carousel-3": "Sementes de Alpiste",
                "carousel-4": "Água para regar",
                "carousel-5": "Um punhado de terra",
            },
            "nave-espacial": {
                "carousel-1": "1 embalagem de leite fermentado Vigor",
                "carousel-2": "2 embalagens de mix Vigor",
                "carousel-3": "2 embalagens de suco vig",
                "carousel-4": "2 embalagens de iogurte líquido 900g",
                "carousel-5": "1 pote de vigorzinho",
                "carousel-6": "Tinta cor cinza",
                "carousel-7": "Dois pincéis",
                "carousel-8": "Cola quente",
                "carousel-9": "Tesoura",
                "carousel-10": "Marcadores permanentes e uma lapiseira",
            },
            "cantina": {
                "titulo": "Nutritivos, saborosos e divertidos, assim são os produtos da linha infantil Vigor. Agora você pode comprar e preparar lanchinhos deliciosos com praticidade, e nem precisa pegar fila.",
                "titulo-2": "Produtos Linha Infantil",
                "carousel-1": "Leite Fermentado",
                "carousel-2": "Leite Fermentado tamanho família",
                "carousel-3": "Vigorzinho sabor morango",
                "carousel-4": "Vigorzinho sabor chocolate",
                "carousel-5": "Achocolatado Infantil",
                "carousel-6": "Sucos Vig",
                "carousel-7": "Mix Vigor",
            },
            "colecoes": {
                "titulo": "Divirta-se conhecendo as coleções da linha infantil da Vigor.",
                "titulo-2": "São diversos temas para brincar e se divertir.",
                "carousel-1": {
                    "titulo": "JUNTOS EM CAMPO",
                    "ano": "2022",
                    "descricao": "VIGOR ENTRA EM CAMPO PARA LEVANTAR A TORCIDA"
                },
                "carousel-2": {
                    "titulo": "MINIONS 2",
                    "ano": "2022",
                    "descricao": "MINIONS 2: A ORIGEM DO GRU"
                },
                "carousel-3": {
                    "titulo": "HALLOWEEN",
                    "ano": "2021",
                    "descricao": "COM ELES É DIVERSÃO E RISADAS GARANTIDAS!"
                },
                "carousel-4": {
                    "titulo": "ESPORTES",
                    "ano": "2021",
                    "descricao": "COM ESSA TURMINHA DIVERTIDA A BRINCADEIRA É CAMPEÃ!"
                },
                "carousel-5": {
                    "titulo": "CONTA + DIVERTIDA",
                    "ano": "2021",
                    "descricao": "COM ESSA TURMINHA VAMOS SOMAR, SUBTRAIR, DIVIDIR E MULTIPLICAR BRINCANDO!"
                },
                "carousel-6": {
                    "titulo": "MONSTROS",
                    "ano": "2020",
                    "descricao": "SUA BRINCADEIRA MAIS GOSTOSA COM UMA TURMA MONSTRUOSA!"
                },
                "carousel-7": {
                    "titulo": "VOLTA ÀS AULAS",
                    "ano": "2020",
                    "descricao": "VIGOR JÁ COMEÇA O ANO NO MUNDO DA IMAGINAÇÃO PARA COMEÇAR A BRINCADEIRA."
                },
                "carousel-8": {
                    "titulo": "JUNTOS EM CAMPO",
                    "ano": "2019",
                    "descricao": "VIGOR ENTRA EM CAMPO PARA LEVANTAR A TORCIDA!"
                },
                "carousel-9": {
                    "titulo": "JUNTOS EM CAMPO",
                    "ano": "2018",
                    "descricao": "ELES VÃO ENTRAR EM CAMPO PARA DEIXAR A TORCIDA DE TODA FAMÍLIA MAIS ANIMADA!"
                },
                "carousel-10": {
                    "titulo": "MINION",
                    "ano": "2017",
                    "descricao": "COM ELES É DIVERSÃO E RISADAS GARANTIDAS!"
                },

            },
            "compre": "Compre agora",
        },
        "workUs": {
            "bloco-1": {
                "paragrafo": "Estamos presentes na rotina de milhões de brasileiros com produtos que são sinônimo de momentos prazerosos, pois o nosso propósito é alimentar uma vida mais gostosa.Porque alimentar é nutrir, incentivar, inspirar.Porque fazer a vida das pessoas - cada uma e todas elas - mais gostosa é tornar as coisas mais leves, mais saudáveis, mais felizes.Somos mais de 4 mil pessoas que cuidam para que marcas como Grego, Faixa Azul, Danubio, Amélia entre outras, cheguem às prateleiras dos pontos de venda e às casas dos nossos consumidores, todos os dias.E para isso, é preciso muito vigor!"
            },
            "bloco-2": {
                "titulo": "Vem ser Vigor!",
                "paragrafo-1": "Ser Vigor pede vigor. Pede energia para ter atitude de dono, inspirando todos à nossa volta. Pede pessoas autênticas que criam valor através do trabalho para sonhar grande, alcançando o inimaginável. Pede praticidade para lidar com os desafios do dia a dia, fazendo sempre o certo e não o mais fácil. Ser Vigor é se identificar com nossos valores e buscar um ambiente dinâmico, com espaço para criar, aprender na prática e fazer parte de um time intenso, aberto e que faz acontecer. Atender mais de 23,6 milhões de lares e aproximadamente 60 milhões de pessoas por ano no país pede vigor. Você tem?",
                "paragrafo-2": "Ser Vigor é se identificar com nossos valores e buscar um ambiente dinâmico, com espaço para criar, aprender na prática e fazer parte de um time intenso, aberto e que faz acontecer. Atender mais de 23,6 milhões de lares e aproximadamente 60 milhões de pessoas por ano no país pede vigor. Você tem?",
                "botao": "Saiba mais",
            },
            "valores": "Valores",
            "vagas": "Nossas vagas",
            "equidade": "Confira o Relatório de Equidade Salarial.",
        },
        "contact": {
            "titulo": "SAC",
            "paragrafo-1": "Para dúvidas, reclamações ou sugestões, entre em contato: ",
            "paragrafo-2": "De 2ª a 5ª feira das 9h00 às 17h00 e 6ª feira das 9h00 às 15h00, exceto feriados.",
            "paragrafo-3": "Se preferir, mande um e-mail: ",
            "titulo-2": "Redes Sociais",
            "formulario": {
                "titulo": "Entre em contato",
                "input-1": "Nome",
                "input-2": "E-mail",
                "input-3": "Endereço",
                "input-4": "Número",
                "input-5": "Complemento",
                "input-6": "Bairro",
                "input-7": "CEP",
                "input-8": "UF",
                "input-9": "Cidade",
                "input-10": "Telefone",
                "input-11": "Celular",
                "input-12": "Enviar arquivos",
                "input-13": "Selecione o Assunto",
                "input-14": "Mensagem",
                "input-15": "Digite a mensagem",
                "botao": "Enviar",
                "opacao-1": "Crítica",
                "opacao-2": "Elogios",
                "opacao-3": "Informações",
                "opacao-4": "Sugestões",
            },
        },
        "newsletter": {
            "titulo": "QUER FICAR POR DENTRO DA VIGOR?",
            "descricao": "Cadastre-se e receba em seu e-mail as notícias da Vigor.",
            "formulario": {
                "titulo": "Assine nossa newsletter!",
                "nome": "Nome",
                "input-1": "Seu nome",
                "email": "E-mail",
                "input-2": "Seu e-mail",
                "botao": "enviar",
                "descricao": "A Vigor oferece o serviço de newsletter com divulgação de informações acerca de promoções, ofertas, receitas e novidades em relação a seus produtos e à empresa. Ao optar por se inscrever neste serviço você aceita o tratamento dos dados pessoais para o envio das comunicações da Vigor."
            }
        },
        "brand": {
            "titulo": "Selecione o que deseja acessar: ",
            "bloco-1": "Produtos para mim",
            "bloco-2": "Produtos para meu negócio",
            "conheca": "Conheça a",
        },
        "category": {
            "titulo-1": "Categorias de produtos",
            "titulo-2": "Confira os produtos",
        },
        "product": {
            "tipo": "Tipo",
            "sabor": "Sabor",
            "embalagem": "Embalagem",
            "informacoes-tabela": "Ingredientes / Informações nutricionais",
            "informacoes": "Informações sobre o produto",
            "ingredientes": "Ingredientes",
            "alergicos": "Alérgicos",
            "nutricional": "Informações Nutricionais",
            "produtos-relacionados": "Produtos relacionados",
            "receitas": "Receitas com",
            "tabela": {
                "qtd": "Quant. por porção",
                "valor-energetico": "Valor energético",
                "carboidratos": "Carboidratos",
                "proteinas": "Proteínas",
                "gorduras-totais": "Gorduras totais",
                "gorduras-saturadas": "Gorduras saturadas",
                "gorduras-trans": "Gorduras trans",
                "gorduras-monoinsaturadas": "Gorduras monoinsaturadas",
                "gorduras-poliinsaturadas": "Gorduras poliinsaturadas",
                "fibra-alimentar": "Fibra alimentar",
                "sodio": "Sódio",
                "calcio": "Cálcio",
                "fosforo": "Fósforo",
                "ferro": "Ferro",
                "zinco": "Zinco",
                "vitamina-a": "Vitamina A",
                "vitamina-b1": "Vitamina B1",
                "vitamina-b2": "Vitamina B2",
                "vitamina-b": "Vitamina B",
                "vitamina-b6": "Vitamina B6",
                "vitamina-b12": "Vitamina B12",
                "vitamina-c": "Vitamina C",
                "vitamina-e": "Vitamina E",
                "acucares": "Açúcares",
                "colesterol": "Colesterol",
                "acido-folico": "Ácido Fólico",
                "lactose": "Lactose",
                "glicose": "Glicose",
                "galactose": "Galactose",
                "frutose": "Frutose",
                "porcoes-por-embalagem": "Porções por embalagem",
                "porcao": "Porção",
                "titulo": "Valor energético (kcal)",
                "totais": "totais",
                "adicionados": "adicionados",
                "disclamer": "Visando melhorar sempre, nossos produtos podem sofrer alterações. Consulte também as informações nas embalagens.",
            },
        },
        "artisanCheese": {
            "bloco-1": {
                "titulo-1": "Sobre a linha",
                "titulo-2": "artesanais do Brasil",
                "paragrafo-1": "A linha de queijos Artesanais do Brasil tem o propósito de democratizar o consumo de queijos especiais e incentivar o crescimento dos queijos artesanais no mercado.",
                "paragrafo-2": "A Vigor busca dar visibilidade aos queijos artesanais e ampliar sua distribuição, por meio de parcerias com produtores locais, com o objetivo de atender a uma parcela de consumidores mais exigentes, que querem conhecer a origem do produto, cobram boas práticas na produção animal (como bem-estar e sanidade), segurança alimentar e desejam experimentar novos sabores.",
                "paragrafo-3": "A linha Artesanais do Brasil traz duas versões especiais, o Queijo Minas Artesanal Cerrado e o Queijo Minas Artesanal Campo das Vertentes, ambos produzidos em microrregiões de Minas Gerais, por meio de processos cuidadosamente artesanais.",
            },
            "bloco-2": {
                "titulo-1": "O que são os Queijos Artesanais?",
                "descricao": "O grande diferencial do queijo artesanal é ser feito com leite cru, a partir de técnicas prioritariamente manuais e por quem tem domínio do processo. Já no paladar, o sabor é marcante e pode variar de acordo com a região em que é feito, o tempo de maturação e até com o processo utilizado na produção.",
            },
            "bloco-3": {
                "titulo-1": "Queijo Minas Artesanal Cerrado",
                "descricao-1": "Proprietário da Granja Leiteira que leva seu nome, Eudes Braga começou a ajudar o pai na cafeicultura desde muito jovem, na região do Alto Paranaíba, no Cerrado, em Minas Gerais. Com mais de 13 anos de experiência no setor queijeiro, hoje Eudes Braga é considerado um dos maiores produtores de queijo minas artesanal de leite cru do Estado e orgulha-se do patrimônio empresarial e social construído ao longo desses anos, contribuindo para o desenvolvimento da região e das famílias dos colaboradores da fazenda e da fábrica de laticínios.",
                "titulo-2": "Queijo Minas Artesanal Campo das Vertentes",
                "descricao-2": "Produzido por Marcondes Moura, na Fazenda Fortaleza, localizada na cidade de Prados, na região de Campo das Vertentes, em Minas Gerais. Há 20 anos atuando no mercado de queijos, Marcondes herdou do seu pai a fazenda, que opera desde 1945.",
            },
            "bloco-4": {
                "titulo-1": "Cerrado",
                "descricao-1": "O Cerrado é a região do Alto Paraíba e está localizado no noroeste do estado de Minas Gerais.",
                "titulo-2": "Campo das vertentes",
                "descricao-2": "O Campo das Vertentes é a região montanhosa formada por declives vertentes das serras e localizada no sudeste de Minas.",
            },
            "bloco-5": {
                "titulo-1": "Principais queijos",
                "titulo-2": "QUEIJO MINAS ARTESANAL",
                "botao": "Saiba mais",
            },
            "bloco-6": {
                "titulo-1": "Se você é um produtor",
                "descricao-1": "gostaria de fazer parte do portfólio Vigor, entre em contato.",
                "descricao-2": "Ou ligue para",
                "botao": "Entrar em contato",
            },
            "inside": {
                "receitas": {
                    "descricao-1": "Com sabor e aroma mais sutil que os demais, normalmente são queijos muito equilibrados em sabor. Sugere-se manter na boca por um pouco mais de tempo para apreciar as sutilezas ( leite fresco, campo, sal, baixa acidez ).",
                    "titulo-1": "Sugestão de receitas para o queijo minas",
                    "descricao-2": "Seja para ser consumido puro, derretido em lanches, acompanhado de doces e compotas ou até mesmo ralado, os queijos artesanais são produtos diferenciados, capazes de trazer um sentimento de conforto, aconchego e memórias gostosas aos consumidores.",
                },
                "carousel-1": {
                    "slide-1": "Doce de Leite",
                    "slide-2": "Goiabada",
                    "slide-3": "Peito de Peru",
                    "slide-4": "Linguiça Suína para Churrasco",
                    "slide-5": "Espumante Moscatel",
                    "slide-6": "Cerveja Estilo Weiss",
                },
                "botao": "Veja as receitas",
                "tabela": {
                    "titulo": "TABELA NUTRICIONAL",
                    "sub-titulo": "Queijo minas artesanal",
                    "porcao": "Porção de",
                    "qtd": "Quant. por porção % VD",
                    "campo-1": "Valor energético",
                    "campo-2": "Carboidratos",
                    "campo-3": "Proteínas",
                    "campo-4": "Gorduras totais",
                    "campo-5": "Gorduras saturadas",
                    "campo-6": "Gorduras trans",
                    "campo-7": "Sódio",
                    "campo-8": "Fibra alimentar",
                    "informacao": "Informação nutricional",
                    "botao": "tabela nutricional",
                },
            },
            "cerrado": {
                "bloco-1": {
                    "titulo": "Cerrado (região do Alto Paranaíba)",
                    "descricao": "O Alto Paranaíba é uma das dez regiões de planejamento do Estado de Minas Gerais. Pertence, juntamente com o Triângulo Mineiro, à Mesorregião do Triângulo Mineiro e Alto Paranaíba.",
                },
                "bloco-2": {
                    "titulo": "Granja Leiteira Eudes Braga",
                    "descricao": "Fazenda com 13 anos de existência, na região do Alto Paranaíba, no Cerrado, em Minas Gerais. Eudes Braga é considerado o maior produtor de queijo minas artesanal de leite cru do Estado. Atualmente com 24 colaboradores que executam as atividades na Fazenda, desde a produção de leite, fabricação de queijos, logística e comercialização, dentre eles veterinários exclusivos.",
                },
                "bloco-3": {
                    "titulo": "Sugestão de consumo para o queijo minas do cerrado",
                },
                "carousel-1": {
                    "slide-1": "Bastonetes de Queijo com Ketchup de Goiabada",
                    "slide-2": "Bolo de Fubá",
                    "slide-3": "Choripán com Chimichurri",
                    "slide-4": "Curau Cake",
                    "slide-5": "Pão de Queijo Minas",
                    "slide-6": "Petit Gateau Romeu e Julieta",
                    "slide-7": "Pudim de Laranja com Queijo",
                    "slide-8": "Queijadinha",
                    "slide-9": "Risoto à Mineira",
                    "slide-10": "Tapioca de Queijo Minas c/ Peito de Peru",
                },
            },
            "campo-das-vertentes": {
                "bloco-1": {
                    "titulo": "Campos das Vertentes (Microregião de São João Del Rei)",
                    "descricao": "O Campo das Vertentes é uma das doze mesorregiões do estado de Minas Gerais. A região é montanhosa, fazendo parte da Serra da Mantiqueira. Dos declives das serras da região escorrem águas de nascentes, riachos e córregos. Suas águas deságuam em rios, que contribuem para a formação das bacias hidrográficas do Paraíba do Sul, São Francisco e Bacia do Paraná. Seus campos nativos têm como característica vegetações rasteiras, formadas nos declives vertentes das serras, por isso o nome Campo das Vertentes.",
                },
                "bloco-2": {
                    "titulo": "Fazenda Fortaleza",
                    "descricao": "Produzido por Marcondes Moura, na Fazenda Fortaleza, localizada na cidade de Prados, na região de Campo das Vertentes, em Minas Gerais. Há 20 anos atuando no mercado de queijos, Moura herdou do pai a fazenda, que opera desde 1945.",
                },
                "bloco-3": {
                    "titulo": "Sugestão de consumo para o queijo Campo das Vertentes",
                },
                "carousel-1": {
                    "slide-1": "Baião de dois",
                    "slide-2": "Batata com costela",
                    "slide-3": "Carbonara à mineira",
                    "slide-4": "Filé Mignon com molho de queijos",
                    "slide-5": "Hamburguer mineiro",
                    "slide-6": "Sanduíche de andar",
                    "slide-7": "Tábua de queijos Campo das Vertentes",
                    "slide-8": "Biscoito mineiro",
                    "slide-9": "Cheesecake da roça",
                    "slide-10": "Batata Rosti",
                },
            }
        },
        "vivProtein": {
            "lista-1": {
                "titulo": "VIV PROTEIN UHT",
                "item-1": "15g de proteína",
                "item-2": "9 aminoácidos essenciais",
                "item-3": "Glutamina",
                "item-5": "Zero adição de açúcares e lactose",
                "item-6": " Ideal para um pré/pós treino! ",
            },
            "lista-2": {
                "titulo": "VIV PROTEIN IOGURTE LÍQUIDO",
                "item-1": "15g de proteína",
                "item-2": "Auxilia na recuperação muscular",
                "item-3": "Com Whey",
                "item-4": "Zero adição de açúcares e lactose",
            },
            "carousel-1": {
                "item-1": {
                    "titulo": "VIGOR VIV PROTEIN UHT",
                    "descricao": "Bebida UHT com 15g de proteína e sabores deliciosos!",
                    "descricao-2": "Sem lactose e adição de açúcares, ideal para um pré/pós treino!",
                    "descricao-3": "Além disso, o Vigor VIV Protein UHT conta com: ",
                    "item-1": "Whey protein e caseína",
                    "item-2": "9 aminoácidos essenciais",
                    "item-3": "Glutamina e BCAA.",
                },
                "item-2": {
                    "titulo": "VIGOR VIV",
                    "titulo-2": "PROTEIN IOGURTE LÍQUIDO",
                    "descricao": "Com 15g de proteína por porção, a maior do mercado, o Vigor VIV Protein Iogurte Líquido ajuda na recuperação muscular, além de ser zero adição de açúcares e lactose é perfeito para qualquer hora do seu dia, dando uma forcinha extra e aquela sensação de saciedade. ",
                },
            },
            "botao": "Tabela nutricional",
            "tabela-nutricional": "TABELA NUTRICIONAL",
            "ingredientes": "Ingredientes",
            "alergicos": "Alérgicos",
        },
        "buscar": {
            "input": "Faça sua busca...",
            "botao": "Pesquisar",
            "botao-2": "Saiba mais",
            "resultado-1": "Resultados da sua busca",
            "resultado-2": "Não encontramos resultados para sua busca !",
            "resultado-3": "Outros resultados que podem ser úteis: ",
            "noticias": "Noticias",
            "realeases": "Realeases",
            "receitas": "Receitas",
            "marcas": "Marcas",
            "produtos": "Produtos",
        },
        "privacyPolicy": {
            "titulo-principal": "DECLARAÇÃO DE PRIVACIDADE EXTERNA",
            "bloco-1": {
                "titulo": "Introdução",
                "paragrafo-1": "Dentro da VIGOR nós somos comprometidos com a segurança dos seus dados pessoais e da sua privacidade durante toda a sua jornada dentro do site institucional da VIGOR e suas marcas, desde o momento que você realiza o acesso a nosso site, o cadastro em nossas plataformas, durante os contatos comerciais com fornecedores e possíveis clientes até o suporte quando você se torna nosso cliente ou fornecedor e quando você consumidor entra em contato ou participa de nossas campanhas promocionais.",
                "paragrafo-2": "Quando você nos contata por nossos canais oficiais de relacionamento com consumidores, visita as nossas instalações, se candidata a vagas ou através das comunicações profissionais realizadas entre a VIGOR e seus clientes e fornecedores, serão solicitadas algumas informações para que possamos realizar o melhor atendimento.",
                "paragrafo-3": "Vale ressaltar que poderemos atualizar este Aviso de Privacidade a qualquer tempo, mediante aviso no site. Recomendamos que você consulte este documento periodicamente para manter-se informado de como a VIGOR está tratando seus dados pessoais.",
            },
            "bloco-2": {
                "titulo": "Objetivos",
                "paragrafo-1": "Este Aviso de Privacidade tem como objetivo explicar de forma clara e transparente como a VIGOR coleta e usa dados pessoais, descrever os direitos do Titular de dados pessoais e demonstrar nossas práticas específicas com relação ao tratamento de dados pessoais.",
            },
            "bloco-3": {
                "titulo": "Conteúdo do Documento",
                "titulo-1": "O que Você Precisa Saber Antes de Ler a Política",
                "paragrafo-1": "Em setembro de 2020 a Lei Geral de Proteção de Dados, lei federal n°13.709/2018 (“LGPD”) passou a disciplinar o tratamento de dados pessoais e dados pessoais sensíveis dos titulares.",
                "titulo-2": "Mas o que são dados pessoais?",
                "paragrafo-2": "Dados pessoais são quaisquer informações relacionadas à uma pessoa natural identificada ou identificável, ou seja, informações diretamente ligadas a uma pessoa natural/física ou informações que possibilitem identificar uma pessoa natural. ",
                "titulo-3": "O que são dados sensíveis?",
                "paragrafo-3": "Algumas categorias de dados pessoais são consideradas sensíveis e merecem uma atenção especial. Os dados considerados sensíveis são todos os dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosó fico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico.",
                "titulo-4": "Quem é o titular dos dados pessoais?",
                "paragrafo-4": "Toda pessoa física a quem os dados pessoais e dados pessoais sensíveis podem identificar. Portanto, todas operações realizadas com alguma informação que possibilite identificar uma pessoa física devem observar os requisitos legais da LGPD.",
                "titulo-5": "Quais Dados Coletamos e Suas Finalidades",
                "paragrafo-5": "O tratamento de dados pessoais pode ocorrer de formas diversas dentro da Vigor, assim trazemos esta divisão por categorias de titulares para melhor informar quanto às formas do tratamento dos dados pessoais em nossos processos.",
                "titulo-6": "Visitantes das Páginas da Vigor",
                "paragrafo-6": "Quando você navega nos sites da VIGOR e de suas marcas coletamos “Cookies”, que são pequenos arquivos de texto gerados, enviados e armazenados em seu próprio computador com a função de identificar, acompanhar e armazenar dados relacionados à sua navegação na Internet. Mais informações sobre o tratamento destes dados podem ser encontradas em nossa Política de Cookies.",
                "paragrafo-7": "Em nossos sites também existem campos para que seja feita a inscrição em nosso newsletter, caso você queira receber informações promocionais ou institucionais da Vigor, ou para que possa participar de pesquisas realizadas por nossas marcas.",
                "titulo-7": "Consumidores",
                "paragrafo-8": "A coleta de dados de consumidores pode se dar quando você acessa ao site da VIGOR e realiza cadastro em nosso newsletter para receber informações promocionais, quando você realiza contato diretamente a VIGOR através de nossos canais de relacionamento com clientes, quando você se inscreve em nossas campanhas de marketing e/ou promoções para a participações em sorteios, incentivos ou descontos, ou por meio de plataformas de terceiros como Reclame Aqui ou Procon referente a nossos produtos.",
            },
            "tabela-1": {
                "titulo": "Categorias de dados",
                "titulo-item-1": "Primeiro nome;",
                "titulo-item-2": "Informação de Contato",
                "descricao-item-2": " - E-mail.",
            },
            "tabela-2": {
                "titulo": "Finalidade",
                "titulo-item-1": "Newsletter promocional",
                "descricao-item-1": "A VIGOR tem a intenção o envio de notícias relacionada à marca, bem como eventuais campanhas de Marketing aos interessados sobre lançamento de produtos e campanhas das marcas existentes.",
            },
            "tabela-3": {
                "titulo": "Categorias de dados ",
                "titulo-item-1": "Nome completo;",
                "titulo-item-2": "Identificação gerada por órgãos oficiais ",
                "descricao-item-2": " - CPF.",
                "titulo-item-3": "Informações de Contato",
                "descricao-item-3": " - Endereço residencial {'|'} Telefone residencial {'|'} Número de celular pessoal {'|'} E-mail pessoal {'|'} Mídias sociais;",
                "titulo-item-4": "Informações profissionais",
                "descricao-item-4": " - Ocupação/Cargo {'|'} Endereço comercial {'|'} Telefone comercial;",
                "titulo-item-5": "Informações Financeiras",
                "descricao-item-5": " - Dados Bancários (Banco. Agência e Conta).",
            },
            "tabela-4": {
                "titulo": "Finalidade",
                "titulo-item-1": "Atendimento ao cliente",
                "descricao-item-1": "Quando o consumidor ou cliente entra em contato com o SAC para reclamar, elogiar, sugerir, pedir informações etc, fazemos o registro dos dados.",
                "descricao-item-2": "Quando se trata de reclamação, pode ser realizada a troca do produto na residência do consumidor ou o envio de um novo produto através dos Correios. Os dados coletados são compartilhados com as empresas que prestam serviço de troca.",
                "descricao-item-3": "Em alguns casos, ao invés de fazermos a troca do produto na residência do consumidor, realizamos o reembolso através de uma conta bancária.",
            },
            "tabela-5": {
                "titulo": "Categorias de dados  ",
                "titulo-item-1": "Nome e iniciais",
                "descricao-item-1": " - Primeiro Nome {'|'} Nome do meio {'|'} Sobrenome;",
                "titulo-item-2": "Características Pessoais",
                "descricao-item-2": " - Idade {'|'} Gênero;",
                "titulo-item-3": "Identificação gerada por órgãos oficiais",
                "descricao-item-3": " - CPF {'|'} RG;",
                "titulo-item-4": "Informações Residenciais",
                "descricao-item-4": "Endereço residencial {'|'} Telefone residencial {'|'} Número de celular pessoal {'|'} E-mail pessoal.",
            },
            "tabela-6": {
                "titulo": "Finalidade",
                "titulo-item-1": "Promoções",
                "descricao-item-1": "Realizamos a contratação de empresas terceiras que nos apoiam na execução de ações promocionais. As informações são coletadas para que possamos ter a identificação do ganhador para entrega de premiação da promoção.",
            },
            "bloco-4": {
                "descricao": "Os fundamentos legais para o tratamento de dados pessoais dos clientes da Vigor são: ",
                "titulo-1": "Cumprimento de uma obrigação legal ou regulamentar: ",
                "descricao-1": "quando requisitado por lei ou órgão regulador que seja realizado o tratamento dos dados pessoais;",
                "titulo-2": "Consentimento: ",
                "descricao-2": "nos casos em que solicitamos a você a autorização para o tratamento, como por exemplo, no envio de e-mail marketing e nas pesquisas de satisfação.",
            },
            "bloco-5": {
                "titulo": "Indivíduos que Correspondem com a Vigor por e-mail",
                "paragrafo-1": "Se o Titular se corresponder por e-mail com um destinatário da Vigor, seus e-mails serão inspecionados pelas ferramentas de segurança da infraestrutura de TI da Vigor, o que poderá resultar na leitura do conteúdo por pessoas autorizadas da Vigor que não o destinatário pretendido.",
                "paragrafo-2": "Os fundamentos legais para o tratamento de dados pessoais de indivíduos que se correspondem com a Vigor por e-mail: ",
                "paragrafo-3": "Interesse legítimo: ",
                "paragrafo-4": "em proteger a infraestrutura de TI contra acesso não autorizado ou vazamento de dados e em analisar o tráfego de e-mails.",
            },
            "bloco-6": {
                "titulo": "Candidatos a Empregos",
                "paragrafo-1": "A Vigor coleta informações sobre os candidatos em relação às oportunidades de emprego disponíveis na companhia diretamente do titular ou através das plataformas de parceiros nas candidaturas realizadas pelos sites de nossos parceiros Vagas.com e LinkedIn. Durante o processo de recrutamento e seleção são coletados os seguintes dados: ",
            },
            "tabela-7": {
                "titulo": "Categorias de dados ",
                "titulo-item-1": "Nome e iniciais",
                "descricao-item-1": "Nome completo",
                "titulo-item-2": "Características Pessoais",
                "descricao-item-2": " - Estado Civil {'|'} Sexo {'|'} Idade",
                "titulo-item-3": "Escolaridade ",
                "descricao-item-3": " - Diplomas e escolaridade",
                "titulo-item-4": "Informações profissionais",
                "descricao-item-4": " - Ocupação/Cargo {'|'} Histórico empregatício declarado pelo funcionário {'|'} Histórico empregatício obtido através de análise / troca de informações",
            },
            "tabela-8": {
                "titulo": "Finalidade",
                "titulo-item-1": "Recrutamento e Seleção",
                "descricao-item-1": "durante este processo serão coletados informações necessárias para que possamos realizar: ",
                "lista-1": "O contato confirmando o interesse na vaga, confirmação da experiência profissional e dos dados pessoais;",
                "lista-2": "Os cadastros nos sistemas internos, viabilizando o processo de entrevista e seleção;",
                "lista-3": "Análise da adequação do candidato ao perfil da vaga, verificando as competências, suas habilidades, experiências anteriores e educação;",
                "lista-4": "O compartilhamento dos dados com os gestores das vagas para validação do perfil do candidato;",
                "lista-5": "Análise dos documentos e elaboração da proposta ao candidato.",
            },
            "bloco-7": {
                "paragrafo-1": "Após o aceite pelo candidato à proposta feita pela Vigor, será realizada a coleta de mais informações sobre o candidato para que sejam realizados os processos internos viabilizando a contratação. O descritivo deste tratamento de dados pode ser encontrado em nossa",
                "paragrafo-2": "Política de Privacidade Interna – POL.CO.JUR.PRIV-004,",
                "paragrafo-3": "disponível a todos os colaboradores e prestadores de serviço.",
                "paragrafo-4": "No âmbito das atividades de recrutamento e seleção, incluindo candidaturas e integração, também poderão ser coletados dados de categoria especial dos candidatos, nos casos em que há a obrigação legal de o fazer, quando permitido pela lei aplicável, a Vigor coletará informações sobre as deficiências de um indivíduo para análise da diversidade da força de trabalho.",
                "paragrafo-5": "As ferramentas de recrutamento e Páginas da Vigor contêm avisos de privacidade explicando por que e como os dados pessoais são coletados e processados por essas Páginas. A Vigor encoraja os indivíduos que utilizam as ferramentas de recrutamento e as Páginas da Vigor a consultar os avisos de privacidade disponíveis.",
                "paragrafo-6": "A Vigor coleta dados pessoais sobre os candidatos a partir das seguintes fontes: ",
                "lista-1": {
                    "item-titulo-1": "De plataformas de recrutamento e seleção: ",
                    "item-descricao-1": "por exemplo, informações que o Indivíduo forneceu ao se candidatar a uma vaga diretamente através do site de carreiras da Vigor;",
                    "item-titulo-2": "Diretamente do Titular do dado pessoal: ",
                    "item-descricao-2": "durante o processo de seleção quando solicitadas informações ao titular;",
                    "item-titulo-3": "Por referência: ",
                    "item-descricao-3": " por exemplo, através de uma referência de um ex-funcionário ou empregador.",
                },
                "paragrafo-7": "Os fundamentos legais para o processamento de dados pessoais dos candidatos a emprego são: ",
                "lista-2": {
                    "item-titulo-1": "Consentimento ",
                    "item-descricao-1": "explícito do candidato;",
                    "item-titulo-2": "Interesse legítimo: 1",
                    "item-descricao-2": " - em atrair, identificar e buscar talentos. 2- Em processar e gerenciar candidaturas para cargos na Vigor, incluindo a seleção de candidatos. 3- Interesse legítimo em gerenciar os sites de carreira da Vigor (incluindo a realização de análises estatísticas).",
                    "item-titulo-3": "Execução de Pré/Contrato",
                    "item-descricao-3": "Em contratar e integrar candidatos, fazendo uma oferta aos candidatos aprovados e realizando verificações de triagem pré-contratação",
                },
            },
            "bloco-8": {
                "titulo": "Fornecedores",
                "descricao": "A Vigor trata dados pessoais dos representantes de seus fornecedores (incluindo subcontratados e indivíduos associados com fornecedores e contratados) para gerenciar o relacionamento e execução deste contrato. Para que possamos realizar nosso relacionamento com o fornecedor serão tratados os seguintes dados pessoais: "
            },
            "tabela-9": {
                "titulo": "Categorias de dados ",
                "titulo-item-1": "Nome e iniciais",
                "descricao-item-1": " - Primeiro Nome {'|'} Nome do meio {'|'} Sobrenome;",
                "titulo-item-2": "Características Pessoais ",
                "descricao-item-2": " - Data e local de Nascimento;",
                "titulo-item-3": "Identificação gerada por órgãos oficiais ",
                "descricao-item-3": " - CPF {'|'} RG {'|'} CNH {'|'} PIS/PASEP;",
                "titulo-item-4": "Informações profissionais",
                "descricao-item-4": " - Endereço comercial {'|'} Telefone comercial {'|'} Celular comercial {'|'} E-mail comercial;",
                "titulo-item-5": "Informações Financeiras",
                "descricao-item-5": " - Dados Bancários (Banco. Agência e Conta) – Aplicável a MEI",
                "titulo-item-6": "Informações gerais sensíveis",
                "descricao-item-6": " – Fotografias.",
            },
            "tabela-10": {
                "titulo": "Finalidade",
                "titulo-item-1": "Due Dilligence",
                "descricao-item-1": " - Prévia às contratações, são realizadas auditorias independentes e outras verificações de antecedentes exigidas por lei ou regulamento, por exemplo, mídia adversa, suborno e corrupção, e outras verificações de crimes financeiros.",
                "titulo-item-2": "Cadastro em nossos sistemas",
                "descricao-item-2": "para que possamos realizar: ",
                "lista-1": "Elaboração/revisão de minuta contratual;",
                "lista-2": "Gestão e realização dos pagamentos devidos à fornecedores;",
                "lista-3": "Gestão do contrato e da relação comercial;",
                "lista-4": "Processos internos de aprovação e liberação de compra;",
                "lista-5": "a emissão de notas fiscais ou outros documentos fiscais obrigatórios por lei.",
                "titulo-item-3": "Auditoria: ",
                "descricao-item-3": "Os dados pessoais dos representantes legais de fornecedores poderão ser tratados para prevenir fraudes e garantir a você segurança no fornecimento a VIGOR.",
            },
            "bloco-9": {
                "titulo": "Os fundamentos legais para o processamento de dados pessoais de fornecedores são: ",
                "lista-1": "Execução de contrato: ",
                "lista-2": "para que sejam realizadas as etapas pré-contratuais necessárias para assinatura do contrato, gerenciar pagamentos, taxas e encargos, e coletar e recuperar o dinheiro devido a Vigor;",
                "lista-3": "Cumprimento de uma obrigação legal ou regulamentar: ",
                "lista-4": "quando somos obrigados por lei a realizar a coleta daquele dado.",
            },
            "bloco-10": {
                "titulo": "Clientes",
                "descricao": "A Vigor trata dados pessoais dos representantes de seus clientes que comercializam os produtos de nossas marcas da seguinte forma: ",
            },
            "tabela-11": {
                "titulo": "Categorias de dados ",
                "titulo-item-1": "Nome e iniciais",
                "descricao-item-1": " - Primeiro Nome {'|'} Nome do meio {'|'} Sobrenome;",
                "titulo-item-2": "Características Pessoais ",
                "descricao-item-2": " - Data e local de Nascimento;",
                "titulo-item-3": "Identificação gerada por órgãos oficiais ",
                "descricao-item-3": " - CPF {'|'} RG {'|'} CNH {'|'} PIS/PASEP;",
                "titulo-item-4": "Informações profissionais",
                "descricao-item-4": " - Endereço comercial {'|'} Telefone comercial {'|'} Celular comercial {'|'} E-mail comercial;",
                "titulo-item-5": "Informações Financeiras",
                "descricao-item-5": " - Dados Bancários (Banco. Agência e Conta) ",
                "descricao-item-6": " *somente será coletado da PF quando se tratar de MEI",
                "descricao-item-7": " Score de crédito {'|'} Renda familiar mensal e patrimônio.",
            },
            "tabela-12": {
                "titulo": "Finalidade",
                "titulo-item-1": "Prospeção de clientes",
                "descricao-item-1": " – As informações de contato profissional são utilizadas para abordagem comercial e início da relação com a VIGOR;",
                "titulo-item-2": "Due Dilligence",
                "descricao-item-2": " - Prévia às contratações, são realizadas auditorias independentes e outras verificações de antecedentes exigidas por lei ou regulamento, por exemplo, mídia adversa, suborno e corrupção, e outras verificações de crimes financeiros.",
                "titulo-item-3": "Cadastro em nossos sistemas ",
                "descricao-item-3": "para que possamos realizar: ",
                "lista-1": "Elaboração/revisão de minuta contratual;",
                "lista-2": "Gestão e realização dos pagamentos devidos à fornecedores;",
                "lista-3": "Gestão do contrato e da relação comercial;",
                "lista-4": "Processos internos de aprovação e liberação de compra;",
                "lista-5": "a emissão de notas fiscais ou outros documentos fiscais obrigatórios por lei.",
                "titulo-item-4": "Tomada de decisões estratégicas",
                "descricao-item-4": " - análise das condições dos clientes para melhor entender o potencial de vendas dos mesmos e das marcas da VIGOR, e direcionar os negócios e investimentos da companhia.",
                "titulo-item-5": "Concessão de Crédito",
                "descricao-item-5": " - sempre que o Cliente necessitar de linha de crédito para compra com a Vigor, serão tratados dados pessoais para tomada de decisão.",
                "titulo-item-6": "Auditoria ",
                "descricao-item-6": " - os dados pessoais dos clientes poderão ser tratados para prevenir fraudes e garantir a você segurança na relação com a VIGOR.",
            },
            "bloco-11": {
                "titulo": "Os fundamentos legais para o processamento de dados pessoais de fornecedores são: ",
                "lista-1": "Execução de contrato: ",
                "lista-2": "para que sejam realizadas as etapas pré-contratuais necessárias para assinatura do contrato, gerenciar pagamentos, taxas e encargos, e coletar e recuperar o dinheiro devido a Vigor;",
                "lista-3": "Cumprimento de uma obrigação legal ou regulamentar: ",
                "lista-4": "quando somos obrigados por lei a realizar a coleta daquele dado;",
                "lista-5": "Interesse legítimo: 1 ",
                "lista-6": " - para mantermos contato comercial com os representantes de nossos Clientes, 2- na análise do potencial de vendas do por área e tomada de decisões estratégicas.",
            },
            "bloco-12": {
                "titulo": "Visitantes as Instalações da Vigor",
                "descricao": "Quando você visita ao escritório e indústrias da Vigor, são coletados e tratados seus dados da seguinte forma: ",
            },
            "tabela-13": {
                "titulo": "Categorias de dados ",
                "titulo-item-1": "Nome e iniciais",
                "descricao-item-1": " - Primeiro Nome {'|'} Nome do meio {'|'} Sobrenome;",
                "titulo-item-2": "Características Pessoais ",
                "descricao-item-2": " - Data e local de Nascimento;",
                "titulo-item-3": "Identificação gerada por órgãos oficiais ",
                "descricao-item-3": " - CPF {'|'} RG {'|'} CNH {'|'} PIS/PASEP;",
                "titulo-item-4": "Informações profissionais",
                "descricao-item-4": " – Imagens das câmeras de monitoramento.",
            },
            "tabela-14": {
                "titulo": "Finalidade",
                "titulo-item-1": "Controle de Acesso",
                "descricao-item-1": " - A fim de fornecer-lhe determinadas facilidades como acesso a nossos prédios e salas de conferência ou Wi-Fi e para controlar o acesso aos nossos prédios.",
                "titulo-item-2": "Segurança Patrimonial",
                "descricao-item-2": " - para proteger nossos escritórios, pessoal, bens e informações confidenciais por exemplo, usando CFTV.",
            },
            "bloco-13": {
                "titulo": "Os fundamentos legais para o processamento de dados pessoais de fornecedores são: ",
                "descricao": " em mantermos o controle de acesso e rastreabilidade das pessoas que tiveram acesso às instalações da Vigor, protegendo a integridade física de nossos colaboradores e nossos segredos de negócio.",
            },
            "bloco-14": {
                "titulo": "Compartilhamento de Dados",
                "paragrafo-1": "A Vigor pode compartilhar os dados pessoais que são coletados com terceiros prestadores de serviços, que são contratados pela Vigor para apoiar nos processos auxiliares internos.",
                "paragrafo-2": "São utilizados apenas fornecedores de serviços/terceiros que são obrigados a manter níveis adequados de proteção de dados, segurança e confidencialidade, e que cumpram com todos os requisitos legais aplicáveis para a transferência internacional de dados pessoais.",
                "paragrafo-3": "A Vigor poderá compartilhar os dados pessoais coletados dos Titulares: ",
                "lista-1": {
                    "item-1": "Quando for apropriado para atingir as finalidades descritas no item",
                    "item-2": " 6.2 Quais Dados Coletamos e Suas Finalidades;",
                    "item-3": "Se determinado pela legislação aplicável;",
                    "item-4": "A fim de cumprir com ordem judicial ou outra obrigação legal, ou um inquérito regulatório ou governamental;",
                    "item-5": "Em casos de reestruturação societária com a fusão, cisão ou incorporação da Vigor;",
                    "item-6": "Para exercício regular de direitos da Vigor de acordo com a LGPD;",
                    "item-7": "Ou com o consentimento do Titular dos dados pessoais.",
                },
                "paragrafo-4": "Os terceiros destinatários dos dados pessoais incluem: ",
                "lista-2": {
                    "item-1": "Consultores profissionais, tais como escritórios de advocacia, consultores fiscais ou auditores;",
                    "item-2": "Seguradoras;",
                    "item-3": "Autoridades fiscais, aduaneiras e dos impostos especiais de consumo;",
                    "item-4": "Órgãos reguladores e outras entidades profissionais;",
                    "item-5": "Tribunais de justiça;",
                    "item-6": "Instituições Financeiras;",
                    "item-7": "Departamentos e agências governamentais;",
                    "item-8": "Prestadores de serviços.",
                }
            },
            "bloco-15": {
                "titulo": "Segurança",
                "paragrafo-1": "A Vigor protege a confidencialidade, integridade e disponibilidade das informações que obtém no curso de seus negócios. O acesso a tais informações é limitado e existem políticas e procedimentos destinados a proteger as informações contra perda, mau uso e divulgação indevida.",
                "paragrafo-2": "São adotados os padrões técnicos e compatíveis com as melhores práticas de mercado para que seja garantida a segurança e proteção dos dados pessoais nos processos da Vigor. As medidas para prevenir incidentes de segurança incluem a proteção física e lógicas dos ativos, comunicações criptografadas, gestão de acesso e políticas internas de auditoria.",
                "paragrafo-3": "Além de ser realizado o tratamento dos dados pessoais de acordo com as disposições desta política, também é realizado o tratamento de dados anonimizados, isso significa que aquele conjunto de informações não pode mais identificar uma pessoa. Também, é realizado tratamento de dados pessoais em formato pseudoanonimizado, onde um dado pessoal é submetido a processo pelo qual perde a possibilidade de associação, direta ou indireta, somente com a utilização adicional mantida em ambiente separado e seguro.",
            },
            "bloco-16": {
                "titulo": "Notificação de Incidente",
                "paragrafo-1": "A Vigor assume a premissa da segurança e conformidades a Lei Geral de Proteção de Dados em seus processos e plataformas. Tendo definido políticas, processos e controles para assegurar a integridade e a proteção dos dados pessoais, incluindo estratégias de segurança adotadas para prevenir que ocorram acessos não autorizados aos nossos bancos de dados.",
                "paragrafo-2": "Caso tenhamos conhecimento de qualquer incidente de segurança iremos, sempre que necessário, informá-lo deste evento, em conformidade com nossas políticas de segurança da informação e com as exigências legais e/ou contratuais vigentes.",
            },
            "bloco-17": {
                "titulo": "Transferência Internacional de Dados",
                "paragrafo-1": "A Vigor transfere dados pessoais coletados no Brasil para servidores localizados no exterior, sendo realizada somente para Parceiros da Vigor que atuam no tratamento de dados pessoais, e essas transferências envolvem apenas empresas que demonstraram estar em conformidade com a LGPD., tendo adotado as salvaguardas necessárias para sua realização, de acordo com O previsto no artigo 33 da LGPD.",
            },
            "bloco-18": {
                "titulo": "Retenção",
                "paragrafo-1": "A Vigor mantém os dados pessoais apenas enquanto forem necessários para o cumprimento das finalidades para as quais foram coletados. Os períodos de retenção variam em diferentes jurisdições e são definidos de acordo com os requisitos regulamentares e profissionais de retenção locais.",
                "paragrafo-2": "Para determinar o período de retenção adequado para os dados pessoais, consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de possíveis incidentes de segurança que possam ocorrer, a finalidade de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios, bem como os requisitos legais aplicáveis.",
                "paragrafo-3": "A fim de atender aos requisitos profissionais e legais, estabelecer, exercer ou defender os direitos legais, e para fins de arquivamento e históricos, a Vigor precisa manter informações por períodos diversos, constantes em suas políticas internas.",
                "titulo-2": "Seus Direitos e Como Exercê-los"
            },
            "tabela-15": {
                "titulo": "Categorias de dados",
                "titulo-2": "Finalidade",
                "titulo-item-1": "Confirmação de tratamento",
                "descricao-item-1": "Você pode solicitar a VIGOR a confirmação da existência de tratamento de seus dados pessoais.",
                "titulo-item-2": "Acesso aos dados",
                "descricao-item-2": "Você pode solicitar a VIGOR o acesso aos dados pessoais que possuímos relacionados a você.",
                "titulo-item-3": "Correção de dados pessoais",
                "descricao-item-3": "Você pode solicitar a VIGOR a correção de dados que estão incompletos, inexatos ou desatualizados, podendo corrigi-los ou complementá-los. Para realizar a correção poderemos requerer que você apresente um documento comprovando a veracidade dos novos dados informados.",
                "titulo-item-4": "Anonimização, bloqueio ou eliminação",
                "descricao-item-4": "Este direito permite que VOCÊ nos peça para anonimizar, bloquear ou eliminar os seus dados pessoais nos seguintes cenários: (a) dados pessoais desnecessários; (b) dados pessoais excessivos; ou (c) dados pessoais tratados em desconformidade com a LGPD.",
                "titulo-item-5": "Eliminação dos dados tratados com o consentimento",
                "descricao-item-5": "Você pode solicitar a eliminação dos dados tratados com base no seu consentimento, sendo que esses serão eliminados. Podendo somente serem mantidos dados que sejam necessários para a prestação dos nossos serviços ou para o cumprimento de obrigações legais ou regulatórias.",
                "titulo-item-6": "Informação acerca do compartilhamento",
                "descricao-item-6": "Você pode solicitar a VIGOR informação das entidades públicas e privadas com as quais tenha sido realizado o compartilhamento dos seus dados.",
                "titulo-item-7": "Informação sobre a possibilidade de não fornecer o consentimento e sobre as consequências da negativa",
                "descricao-item-7": "Caso seja indispensável o seu consentimento para acessar determinado produto ou serviço da VIGOR você pode solicitar a nós informação sobre a possibilidade de não fornecer o consentimento e quais são as consequências negativas decorrentes desta ação.",
                "titulo-item-8": "Revogação do consentimento",
                "descricao-item-8": "Caso você tenha fornecido o seu consentimento para o tratamento de dados, você poderá revogá-lo a qualquer tempo. Destacamos que isso não quer dizer que nós não podemos mais tratar os seus dados, estes poderão ser tratados de forma anonimizada ou com base em outra hipótese autorizativa legal que respalde o tratamento.",
                "titulo-item-9": "Portabilidade de dados",
                "descricao-item-9": "Após a regulamentação desse direito pela Autoridade competente segundo a LGPD, você poderá solicitar a portabilidade dos seus dados pessoais a outro fornecedor de serviço ou produto.",
            },
            "bloco-19": {
                "titulo": "1.1 Entrar em Contato com a Vigor",
                "paragrafo-1": "Caso você queira exercer algum desses direitos acima expostos ou possua qualquer dúvida relaciona da ao tratamento de dados pessoais, entre em contato conosco através do nosso site",
                "paragrafo-2": "ou diretamente com o nosso Encarregado de dados pelo e-mail",
                "paragrafo-3": "Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade, para sua própria proteção. Além disso, podemos rejeitar requerimentos, caso sejam ilegais.",
                "paragrafo-4": "Para exercer algum direito, você precisará fazer um requerimento expresso a Vigor, podendo realizá-lo através do site",
                "paragrafo-5": "Caso tenha alguma dúvida, fique à vontade para nos contatar através do e-mail",
                "paragrafo-6": "Caso necessite de qualquer auxílio para exercer seus direitos, o Usuário pode entrar em contato com a Vigor, conforme orientações nesta Política.",
            },
            "botao-politica-qualidade": "Política de Qualidade",
        },
        //apagar
        "nossa-cultura": {
            "titulo": "Nossa paixão é alimentar a sua vida",
            "paragrafo-2": "Acreditamos que nosso resultado vem da nossa dedicação! Para chegar até aqui foi necessário estabelecer pilares sólidos, com a certeza de que temos as melhores pessoas trabalhando conosco.",
            "paragrafo-3": "Nossas pessoas transformam obstáculos em oportunidades e são desafiadas a darem o melhor de si em um ambiente dinâmico e cheio de desafios.",
            "paragrafo-4": "Buscamos pessoas que compartilhem dos nossos valores e acrescente com a nossa cultura. Ela traduz nosso jeito de ser e nos orienta diariamente, nas decisões, na busca de profissionais e na estratégia do negócio.",
            "paragrafo-5": "Nossa cultura é embasada por uma missão clara e por valores fortes, que vivenciamos e buscamos constantemente aprimorá-los.",
            "paragrafo-6": "Acreditamos no sucesso fortalecido por pessoas que enriquecem a nossa cultura, vivem os nossos valores e contribuem com o propósito de dia após dia alimentarmos vidas mais gostosas.",
            "titulo-2": "Missão",
            "paragrafo-7": "Sermos os melhores naquilo que nos propusermos a fazer, com foco absoluto em nossas atividades, garantindo os melhores produtos e serviços aos clientes, solidez aos fornecedores, rentabilidade aos acionistas e a possibilidade de um futuro melhor a todos os nossos colaboradores.",
            "titulo-3": "Valores",
            "nossa-cultura": "Nossa cultura",
            "investidores": "Relação com investidores",
        }
    }
}